

import { BACKEND_KEY, BackendService } from '@/services/backend-service';

import { defineComponent } from 'vue';

import { IState } from './state';

export default defineComponent({

  inject: [ 'state', BACKEND_KEY ],

  computed: {
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    },
    activeIndex(): string {
      switch (this.$route.name) {
        case "Home": return "home";
        case "Banners": return "banners";
        case "Missions": return "missions";
        case "Map": return "map";
        case "Banner": return "banners";
        case "Add": return "banners-add";
        case "EditProfile": return "profile-edit";
        case "Login": return "profile-login";
        case "Logout": return "profile-logout";
        default: return "";
      }
    },
    STATE(): IState {
      return (<any>this).state as IState;
    },
    isLoggedIn(): boolean {
      return this.STATE.user != null;
    }
  },

  data() {
    return {
      sidebar: false,

      items: [
          {
              label: 'Banners',
              icon:'pi pi-fw pi-microsoft',
              to: '/'
          },
          {
              label: 'Missions',
              icon:'pi pi-fw pi-flag',
              to: '/missions'
          },
          {
              label:'Manage',
              icon:'pi pi-fw pi-pencil',
              visible: () => this.isLoggedIn,
              items:[
                {
                    label: 'Create',
                    icon: 'pi pi-fw pi-pencil',
                    to: '/add'
                },
                {
                    disabled: true,
                    label:'List',
                    icon:'pi pi-fw pi-bars'
                }
              ]
          },
          {
              label: 'Resources',
              icon: 'pi pi-fw pi-external-link',
              items:[
                {
                    label:'IITC',
                    icon:'pi pi-fw pi-external-link',
                    url: 'https://iitc.app',
                    target: '_blank'
                },
                {
                    label: 'IITC plug-ins',
                    icon: 'pi pi-fw pi-external-link',
                    items: [
                      {
                        label: 'Mission plug-in',
                        icon:'pi pi-fw pi-external-link',
                        url: 'https://iitc.app/build/release/plugins/missions.user.js',
                        target: '_blank'
                      },
                      {
                        label: 'Mission Add-on plug-in',
                        icon:'pi pi-fw pi-external-link',
                        url: 'https://softspot.nl/ingress/plugins/iitc-plugin-missions-addon.user.js',
                        target: '_blank'
                      },
                      {
                        label: 'specops.quest plug-in',
                        icon:'pi pi-fw pi-external-link',
                        url: '/assets/iitc-plugin-specops-quest-helper.user.js',
                        target: '_blank'
                      }
                    ]
                },
                {
                  separator: true
                },
                {
                    label:'Intel',
                    icon:'pi pi-fw pi-external-link',
                    url: 'https://intel.ingress.com',
                    target: '_blank'

                },
                {
                    label:'Mission Creator',
                    icon:'pi pi-fw pi-external-link',
                    url: 'https://missions.ingress.com',
                    target: '_blank'
                },
                {
                    label:'Ingress',
                    icon:'pi pi-fw pi-external-link',
                    url: 'https://ingress.com',
                    target: '_blank'
                }
              ]
          },
          {
              label:'Profile',
              icon:'pi pi-fw pi-user',
              items:[
                {
                    label:'Log in',
                    icon:'pi pi-fw pi-sign-in',
                    to: '/login',
                    visible: () => !this.isLoggedIn

                },
                {
                    label:'Edit',
                    icon:'pi pi-fw pi-user-edit',
                    to: '/profile',
                    visible: () => this.isLoggedIn

                },
                {
                    label:'Log out',
                    icon:'pi pi-fw pi-sign-out',
                    to: '/logout',
                    visible: () => this.isLoggedIn
                }
              ]
          }
        ]



    }
  },

  async mounted() {
    const state = (<any>this)["state"] as any;
    // console.log("STATE IS", state);
    let user = null;
    try {
      if (!state.user) {
        state.user = await this.backendService.getAuth();
        // console.log("USER IS", state.user)
      } else {
        // console.log("USER WAS ALREADY", user)
      }
    } catch (err) {
      console.error(err);
      console.log("USER CHECK FAILED", err);
      state.user = null;
    }
    // console.log("USER SET READY");
    state.userReady = true;
  },

  methods: {
    go(name: string, params?: any) {
      if (params) this.$router.push({ name, params })
      else this.$router.push({ name });
    }
  }
});

