

import { defineComponent, PropType } from 'vue';
import { formatDistance, formatTime, formatRating, formatEfficiency } from '../lib/utils';
import { stripGoogleImageParams } from '../../../shared/src/googleImageParams';

import {
  MissionInfo,
  IMissionInfoDetailed,
  IMissionWaypointEx,
  MissionOrder,
  WaypointType,
  WaypointObjective,
  WaypointObjectiveTexts
} from '../../../shared/src/types';

import { icons } from '../lib/icons';

export default defineComponent({

  name: 'MissionList',

  props: {
    showIndex: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    },
    showDetails: {
      type: Boolean,
      default: false
    },
    missions: {
      type: Object as PropType<IMissionInfoDetailed[]>,
      required: true
    },
    selectable: {
      type: Boolean,
      default: false
    },
    sortable: {
      type: Boolean,
      default: false
    },
    showType: {
      type: Boolean,
      default: false
    },
    showNumCompleted: {
      type: Boolean,
      default: false
    },
    showDistance: {
      type: Boolean,
      default: false
    },
    showTime: {
      type: Boolean,
      default: true
    },
    showRating: {
      type: Boolean,
      default: true
    },
    showWaypoints: {
      type: Boolean,
      default: true
    },
    allowDrag: {
      type: Boolean,
      default: false
    },
    allowReorder: {
      type: Boolean,
      default: false
    },
    allowDrop: {
      type: Boolean,
      default: false
    },
    selection: {
      type: Array,
      default: []
    }
  },

  watch: {
    'missions': {
      deep: true,
      immediate: true,
      handler(to) {
        this.missionsList = to;
      }
    },
    'selection': {
      immediate: true,
      handler(to: Array<MissionInfo>) {
        console.log("watch: selection", to)
        let sel: any = {};
        to.forEach(m => sel[m.guid] = true);
        this.selected = sel;
      }
    }
  },

  data() {
    return {
      missionsList: [] as IMissionInfoDetailed[],
      expandedList: {} as {[id: string]: boolean},
      selected: {} as {[id: string]: boolean},
      icons
    }
  },

  methods: {

    formatDistance,
    formatTime,
    formatRating,
    formatEfficiency,

    scaleDownGoogleImage(im: string) {
      return stripGoogleImageParams(im, "=w40");
    },

    selectionChanged() {
      console.log("selection changed")
      let sel = Object.keys(this.selected).map(guid => this.selected[guid] ? this.missionsList.find(m => m.guid == guid) : null).filter(m => m);
      console.log("new sel", sel)
      this.$emit('update:selection', sel);      
    },
    
    toggleExpandAll() {
      let expand = true;
      let keys = Object.keys(this.expandedList);
      if (keys.length > 0) expand = !this.expandedList[keys[0]];
      this.expandedList = {};
      this.missionsList?.forEach(m => this.expandedList[m.guid] = expand);
    },

    formatMissionOrder(order: MissionOrder): string {
      return MissionOrder[order];
    },

    formatWaypointType(type: WaypointType): string {
      return WaypointType[type];
    },

    formatWaypointObjective(objective: WaypointObjective): string {
      return WaypointObjectiveTexts[objective];
    },

    moveUp(row: IMissionInfoDetailed) {
      let i = this.missionsList.indexOf(row);
      if (i > 0) {
        let move = this.missionsList.splice(i, 1);
        this.missionsList.splice(i - 1, 0, ...move);
      }
      this.$emit('update:missions', this.missionsList)
    },

    moveDown(row: IMissionInfoDetailed) {
      let i = this.missionsList.indexOf(row);
      if (i < this.missionsList.length - 1) {
        let move = this.missionsList.splice(i, 1);
        this.missionsList.splice(i + 1, 0, ...move);        
      }
      this.$emit('update:missions', this.missionsList)
    }

  }
});
