

const colorRange = require("colour-range").default;

import { defineComponent, PropType } from 'vue';

interface IMissionSequence {
  mission: IMissionInfoDetailed,
  index: number
}

const {
  LLayerGroup,
  LControl,
  LCircle,
  LCircleMarker,
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LTooltip,
  LPopup,
  LPolyline,
  LPolygon,
  LRectangle
} = require("@vue-leaflet/vue-leaflet");

import "leaflet/dist/leaflet.css";

import {
  filterValidWaypoints,
  findFirstWaypoint,
  findLastWaypoint,
  IMissionInfoDetailed,
  IMissionWaypointEx,
  IBanner,
  WaypointObjectiveTextsShort
} from '../../../shared/src/types';

import {
  IRouteResponse
} from '../../../backend/src/lib/graphhopper';

// interface ILatLng {
//   lat: number;
//   lng: number;
// }

type LatLng = Array<number>[2] | { lat: number, lng: number };

import L from 'leaflet';
import IngressMap from './IngressMap.vue';

export default defineComponent({

  name: 'BannerMap',

  props: {

    banner: {
      type: Object as PropType<IBanner>,
      required: false
    },

    missions: {
      type: Object as PropType<IMissionInfoDetailed[]>,
      required: true
    },

    focusMission: {
      type: Object as PropType<IMissionInfoDetailed | null>,
      required: false
    }

  },

  computed: {

    missionSequence(): Array<IMissionSequence> {
      if (this.missions) {
        return this.missions.map((m,i) => ({
          index: i,
          mission: m
        })).filter(m => {
          return this.focusIndex == null || this.focusIndex -1 == m.index
        })
      } else {
        return [];
      }
    },

    missionStarters(): Array<IMissionSequence> {
      if (this.missions && !this.showWaypoints) {
        return this.missionSequence;
      } else {
        return [];
      }
    },

    waypoints() {
      if (this.missions && this.showWaypoints) {
        let list: any[] = [];
        let index = 0;
        this.missions.forEach((m,i) => {
          list.push(...filterValidWaypoints(m).map(w => {
            return {
              index: index++,
              missionIndex: i,
              waypoint: w,
              mission: m
            }
          }));
        })
        list = list.filter(w => {
          return this.focusIndex == null || this.focusIndex -1 == w.missionIndex
        })
        return list;
      } else {
        return [];
      }
    },

    focusedMission(): IMissionInfoDetailed | undefined {
      return this.focusIndex ? this.missions[this.focusIndex-1] : undefined
    },

    focusedIndex(): number {
      if (this.focusMission != null) {
        let m = this.missions.find(x => x.guid == this.focusMission!.guid);
        let index = this.missions.indexOf(m!);
        return index;
      } else {
        return -1;
      }
    },

    missionConnectors() {
      if (this.missions) {
        let list = [];
        for (let i = 1; this.missions.length > i; i++) {
          let t = this.missions[i];
          let p = this.missions[i-1];
          let w0 = findLastWaypoint(p);
          let w = findFirstWaypoint(t);
          if (w && w0 && this.focusIndex == null) {
            list.push([
              [w0.point.lat, w0.point.lng],
              [w.point.lat, w.point.lng]
            ]);
          }
        }
        return list;
      } else return [];
    }
  },

  components: {
    LLayerGroup,
    LControl,
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LTooltip,
    LCircle,
    LPopup,
    LPolyline,
    LPolygon,
    LRectangle,
    LCircleMarker,
    IngressMap
  },

  data() {
    return {
      WaypointObjectiveTextsShort,
      watchId: 0,
      focusIndex: null as number | null,
      here: undefined as L.LatLng | undefined,
      showWaypoints: false,
      fullscreen: false,
      startCircleRadius: 7,
      startCircleRadiusOutline: 2,
      startCircleFontSize: 15,
      waypointCircleRadius: 4,
      waypointCircleRadiusOutline: 2,
      isReady: false,
      colors: [],
      zoom: 15,
      iconWidth: 25,
      iconHeight: 40,
      center: {lat: 59.91838719629693, lng: 10.747590065002443} as LatLng
    };
  },

  methods: {

    showHere() {
      let first = true;
      navigator.geolocation.clearWatch(this.watchId);
      this.watchId = navigator.geolocation.watchPosition(pos => {
        this.here = new L.LatLng(pos.coords.latitude, pos.coords.longitude);
        if (first) this.center = this.here;
        first = false;
        }, err => {
      })
    },

    getColor(i: number) {
      if (this.focusMission != null) {
        let index = this.focusedIndex;
        return index == i ? this.colors[i] : '#999';
      } else 
        return this.colors[i];
    },

    mapMoved() {
      let map = <{ center: LatLng, zoom: number }> this.$refs.map; 
      // console.log("map moved: %o c=%o z=%s", map, map.center, map.zoom);
    },

    getMissionLatLngs(m: IMissionInfoDetailed) {
      return filterValidWaypoints(m).map(w => [ w.point.lat, w.point.lng ]);
    },

    getMissionRouteLatLngs(m: IMissionInfoDetailed) {
      if (this.banner) {
        let route = this.banner?.routes?.[m.guid]?.graphhopper as IRouteResponse;
        let path = route?.paths?.[0];
        if (path && path.points) {
          if (typeof path.points != 'string') {
            return path.points.coordinates.map(p => ({ lat: p[1], lng: p[0] }));
          }
        }
      }
      return [];
    },

    getMissionWaypoints(m: IMissionInfoDetailed) {
      return filterValidWaypoints(m);
    },

    getMissionStartingLatLng(m: IMissionInfoDetailed) {
      let first = findFirstWaypoint(m);
      if (first == null) debugger;
      return [ first?.point.lat, first?.point.lng ];
    },

    init() {
      this.isReady = true;
      this.updateMap();
    },

    updateMap() {
      // console.log("UPDATE MAP")
      const { missions } = this;
      if (missions) {
        this.colors = colorRange(missions.length).map((c: string) => '#' + c);
        // console.log("COLORS", this.colors);
        let latMax = -360, latMin = -360, lngMin = -360, lngMax = -360;
        let num = 0;
        let lats = 0;
        let lngs = 0;
        missions.forEach(m => {
          m.waypoints?.forEach(wp => {
            if (wp.point && wp.point.lat && wp.point.lng) {

              latMax = (latMax == -360) ? wp.point.lat : Math.max(latMax, wp.point.lat);
              latMin = (latMin == -360) ? wp.point.lat : Math.min(latMin, wp.point.lat);

              lngMax = (lngMax == -360) ? wp.point.lng : Math.max(lngMax, wp.point.lng);
              lngMin = (lngMin == -360) ? wp.point.lng : Math.min(lngMin, wp.point.lng);

              num++;
              lats += wp.point!.lat;
              lngs += wp.point!.lng;
            }
          })
        });

        let mapRef: any = this.$refs.map;
        //console.log("map", mapRef, mapRef.leafletObject);
        let leaflet: L.Map = mapRef.leafletObject;
        //console.log("leaflet", leaflet);
        //console.log("bbbounds! %o", [latMin, lngMin], [latMax, lngMax]);
        let bounds = new L.LatLngBounds([latMin, lngMin], [latMax, lngMax]);
        //leaflet.fitBounds(bounds);
        (<any>leaflet).fitBounds([[latMin,lngMin], [latMax,lngMax]]);

        //this.center = { lat: lats/num, lng: lngs/num };
      }
    },

    getNumberIcon(n: number): L.Icon {
      
      let sz = this.startCircleFontSize;
      
      let svg = `<svg width="${sz}" height="${sz}" viewbox="-10 -10 110 110">
        <style>
        .name-text {
          font-size:  18px;
          paint-order: stroke;
          stroke: #000000;
          stroke-width: 1px;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          font-weight: 800;
        }
        </style>
        <text
          x="50"
          y="75"
          style="font: 70px sans-serif; font-weight: bold; fill: #FFFFFF; text-anchor: middle; stroke: #000000; paint-order: stroke; stroke-width: 25px; stroke-linecap: butt; stroke-linejoiner: miter;"
          >${n}</text>
      </svg>`;

      var myIcon = L.divIcon({
        className: 'my-div-icon',
        iconSize: [ sz, sz ],
        iconAnchor: [ sz/2, sz/2 ],
        html: svg
      });

      return myIcon;
    },

    showAll() {
      this.focusIndex = null;
      this.updateMap();
    },

    showFirst() {
      this.focusIndex = 1;
      this.showWaypoints = true;
      this.center = findFirstWaypoint(this.missions[0])?.point!;
    },

    showNext() {
      this.focusIndex = 1 + (this.focusIndex! + 0) % this.missions.length; 
      this.center = findFirstWaypoint(this.missions[this.focusIndex-1])?.point!;
    },

    showPrevious() {
      this.focusIndex = 1 + (this.focusIndex! - 2 + this.missions.length) % this.missions.length; 
      this.center = findFirstWaypoint(this.missions[this.focusIndex-1])?.point!;
    }

  },

  created() {
    this.fullscreen = this.$route.query.fullscreen == "true";
  },

  watch: {

    missions: {
      immediate: true,
      handler(val: IMissionInfoDetailed[]) {
        if (this.isReady) this.updateMap();
      }

    }

  }
});

