import { reactive, createApp, InjectionKey, watch } from 'vue';
import App from './App2.vue';
import router from './router';

//import ElementPlusComponents from './plugins/ElementPlusComponentsAll';

import TwoColumnLayout from './components/TwoColumnLayout.vue';
import AgentName from './components/AgentName.vue';
import AutoScaleImage from './components/AutoScaleImage.vue';
import IntelLink from './components/IntelLink.vue';
import SeparatedList from './components/SeparatedList.vue';

import BannerEditor from './components/BannerEditor.vue';
import BannerGrid from './components/BannerGrid.vue';
import BannerImage from './components/BannerImage.vue';
import BannerStatsTable from './components/BannerStatsTable.vue';
import MissionList from './components/MissionList.vue';
import BannerMosaicPreview from './components/BannerMosaicPreview.vue';
import BannerMap from './components/BannerMap.vue';
import MapRef from './components/MapRef.vue';
import BannerToolsPopup from './components/BannerToolsPopup.vue';

const { Splitpanes, Pane } = require("splitpanes");
//import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

import { BACKEND_KEY, BackendService } from './services/backend-service';

// https://vuejsdevelopers.com/2020/10/05/composition-api-vuex/

import { state } from './state';

import TitlePlugin from './plugins/TitlePlugin';

const app = createApp(App, {
//  provide: {
//    state
//  }
});

const VueResizeObserver = require("vue-resize-observer");
app.use(VueResizeObserver) // use is a instance's method & be called before mount

const backendService = new BackendService(`/api/`);

app.component('Splitpanes', Splitpanes);
app.component('Pane', Pane);

[
  TwoColumnLayout,
  AgentName,
  AutoScaleImage,
  SeparatedList,
  IntelLink,
  BannerEditor,
  BannerGrid,
  BannerImage,
  BannerStatsTable,
  MissionList,
  BannerMosaicPreview,
  BannerMap,
  MapRef,
  BannerToolsPopup,
  FlexDetect
].forEach(c => {
  //console.log("register component:", c.name, c);
  app.component(c.name, c)
});

// require("@element-plus/icons");

import PrimeVue from 'primevue/config';

// import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
// import "primevue/resources/themes/bootstrap4-light-purple/theme.css";
// import "primevue/resources/themes/bootstrap4-dark-blue/theme.css";
// import "primevue/resources/themes/bootstrap4-dark-purple/theme.css";
// import "primevue/resources/themes/md-light-indigo/theme.css";
// import "primevue/resources/themes/md-light-deeppurple/theme.css";
// import "primevue/resources/themes/md-dark-indigo/theme.css";
// import "primevue/resources/themes/md-dark-deeppurple/theme.css";
// import "primevue/resources/themes/mdc-light-indigo/theme.css";
// import "primevue/resources/themes/mdc-light-deeppurple/theme.css";
// import "primevue/resources/themes/mdc-dark-indigo/theme.css";
// import "primevue/resources/themes/mdc-dark-deeppurple/theme.css";
// import "primevue/resources/themes/fluent-light/theme.css";
// import "primevue/resources/themes/saga-blue/theme.css";
// import "primevue/resources/themes/saga-green/theme.css";
// import "primevue/resources/themes/saga-orange/theme.css";
// import "primevue/resources/themes/saga-purple/theme.css";
// import "primevue/resources/themes/vela-blue/theme.css";
// import "primevue/resources/themes/vela-green/theme.css";
// import "primevue/resources/themes/vela-orange/theme.css";
// import "primevue/resources/themes/vela-purple/theme.css";

// import "primevue/resources/themes/arya-blue/theme.css";
// import "primevue/resources/themes/arya-green/theme.css";
// import "primevue/resources/themes/arya-orange/theme.css";
// import "primevue/resources/themes/arya-purple/theme.css";

// import "primevue/resources/themes/nova/theme.css";
// import "primevue/resources/themes/nova-alt/theme.css";
// import "primevue/resources/themes/nova-accent/theme.css";
// import "primevue/resources/themes/nova-vue/theme.css";
// import "primevue/resources/themes/luna-amber/theme.css";
 // import "primevue/resources/themes/luna-blue/theme.css";
// import "primevue/resources/themes/luna-green/theme.css";
// import "primevue/resources/themes/luna-pink/theme.css";
// import "primevue/resources/themes/rhea/theme.css";

// import "primevue/resources/themes/arya-green/theme.css";
// import "primevue/resources/themes/arya-orange/theme.css";
// import "primevue/resources/themes/md-dark-indigo/theme.css";
import "primevue/resources/themes/mdc-dark-indigo/theme.css";

//import 'primeflex/primeflex.css'; // why doesn't this work in production build??
import './styles/primeflex.scss';

import "primevue/resources/primevue.min.css";                 //core css
import "primeicons/primeicons.css";                           //icons

import Button from 'primevue/button';
import Menubar from 'primevue/menubar';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import Checkbox from 'primevue/checkbox';
import Steps from 'primevue/steps';
import Card from 'primevue/card';
import TabMenu from 'primevue/tabmenu';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';   
import ScrollPanel from 'primevue/scrollpanel';   
import Panel from 'primevue/panel';
import Paginator from 'primevue/paginator';   
import Dropdown from 'primevue/dropdown';   
import TextArea from 'primevue/textarea';   
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider'
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import OverlayPanel from 'primevue/overlaypanel';
import Breadcrumb from 'primevue/breadcrumb';
import RadioButton from 'primevue/radiobutton';
import Rating from 'primevue/rating';
import Sidebar from 'primevue/sidebar';

import Tree from 'primevue/tree';
import FlexDetect from './components/FlexDetect.vue';

app.use(TitlePlugin, { separator: ' ⸗ ' });

app.use(PrimeVue);
app.component('p-sidebar', Sidebar);
app.component('p-rating', Rating);
app.component('p-tree', Tree);
app.component('p-breadcrumb', Breadcrumb);
app.component('p-accordion', Accordion);
app.component('p-accordion-tab', AccordionTab);
app.component('p-divider', Divider);
app.component('p-scroll-panel', ScrollPanel);
app.component('p-data-table', DataTable);
app.component('p-column', Column);
app.component('p-column-group', ColumnGroup);
app.component('p-button', Button);
app.component('p-menubar', Menubar);
app.component('p-input-text', InputText);
app.component('p-input-number', InputNumber);
app.component('p-text-area', TextArea);
app.component('p-multi-select', MultiSelect);
app.component('p-checkbox', Checkbox);
app.component('p-radiobutton', RadioButton);
app.component('p-steps', Steps);
app.component('p-card', Card);
app.component('p-tabmenu', TabMenu);
app.component('p-paginator', Paginator)
app.component('p-dropdown', Dropdown);
app.component('p-dialog', Dialog);
app.component('p-panel', Panel);
app.component('p-overlay-panel', OverlayPanel);
app.provide(BACKEND_KEY, backendService);
app.provide("state", state);

import draggable from 'vuedraggable';
app.component('draggable', draggable);

//app.use(ElementPlusComponents);
app.use(router);
app.mount('#app');
