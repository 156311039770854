<template>
  <p-scroll-panel>

    <table class="missions" v-if="missionsList && missionsList.length">
      <thead>
        <tr>
          <th width="50" v-if="selectable"></th>
          <th width="50"></th>
          <th v-if="showIndex" width="50">#</th>
          <th>Title</th>
          <th v-if="showWaypoints" width="100" class="r"><i :class="icons.waypoint" /> Waypoints</th>
          <th v-if="showDistance" width="100" class="r"><i :class="icons.distance" /> Distance</th>
          <th v-if="showTime" width="100" class="r"><i :class="icons.time" /> Time</th>
          <th v-if="allowReorder && false" class="r" width="100"></th>
          <th v-if="allowReorder" class="r" width="25"></th>
        </tr>
      </thead>
      <draggable
        :disabled="!allowReorder"
        tag="tbody" 
        v-model="missionsList" 
        group="missions" 
        item-key="guid"
        handle=".dragHandle"
        @change="$emit('update:missions', missionsList)"
        @update="$emit('update:missions', missionsList)"
        >
        <template #item="{element, index}">
          <tr>
            <td v-if="selectable">
              <input type="checkbox" v-model="selected[element.guid]" @change="selectionChanged" />
            </td>
            <td @click="expandedList[element.guid] = !expandedList[element.guid]">
              <i class="fas fa-folder-open" v-if="expandedList[element.guid]" />
              <i class="fas fa-folder" v-else />
            </td>
            <td v-if="showIndex">{{ index+1 }}</td>
            <td>
              <div class="titleRow">
                <div class="image">
                  <img :src="scaleDownGoogleImage(element.image)">
                </div>
                <div class="title">{{ element.title }}</div>
              </div>

              <div class="details" v-if="expandedList[element.guid]">

                <div class="description">{{element.description}}</div>

                <div class="p-grid">
                  <div class="p-col-4 p-text-left"><i :class="icons.agent"></i> <AgentName :agent="element.author" /></div>
                  <div class="p-col-4 p-text-center"><i :class="icons.waypoint"></i> {{ element.numWaypoints }} <span class="numUniques">({{ element.numUniques }})</span></div>
                  <div class="p-col-4 p-text-right"><i :class="icons.rating"></i> {{ formatRating(element.rating) }}</div>
                </div>
                <div class="p-grid">
                  <div class="p-col-6 p-text-left"><i :class="icons.time"></i> {{ formatTime(element.time) }}</div>
                  <div class="p-col-6 p-text-right"><i :class="icons.distance"></i> {{ formatDistance(element.distance) }}</div>
                </div>

              </div>

            </td>
            <td class="r" v-if="showWaypoints">{{ element.numWaypoints }}</td>
            <td class="r" v-if="showDistance">{{ formatDistance(element.distance) }}</td>
            <td class="r" v-if="showTime">{{ formatTime(element.time) }}</td>
            <td v-if="allowReorder && false">
              <p-button icon="fas fa-arrow-up" class="p-button-rounded" style="margin-right: 0.25em" @click="moveUp(mission)" />
              <p-button icon="fas fa-arrow-down" class="p-button-rounded" @click="moveDown(element)" />
            </td>
            <td v-if="allowReorder" class="dragHandle">
              <i class="fas fa-ellipsis-v"></i>
            </td>
          </tr>
        </template>
      </draggable>
    </table>
    <div v-else>
      <slot name="empty">
      </slot>
    </div>

  </p-scroll-panel>
</template>

<script lang="ts">

import { defineComponent, PropType } from 'vue';
import { formatDistance, formatTime, formatRating, formatEfficiency } from '../lib/utils';
import { stripGoogleImageParams } from '../../../shared/src/googleImageParams';

import {
  MissionInfo,
  IMissionInfoDetailed,
  IMissionWaypointEx,
  MissionOrder,
  WaypointType,
  WaypointObjective,
  WaypointObjectiveTexts
} from '../../../shared/src/types';

import { icons } from '../lib/icons';

export default defineComponent({

  name: 'MissionList',

  props: {
    showIndex: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    },
    showDetails: {
      type: Boolean,
      default: false
    },
    missions: {
      type: Object as PropType<IMissionInfoDetailed[]>,
      required: true
    },
    selectable: {
      type: Boolean,
      default: false
    },
    sortable: {
      type: Boolean,
      default: false
    },
    showType: {
      type: Boolean,
      default: false
    },
    showNumCompleted: {
      type: Boolean,
      default: false
    },
    showDistance: {
      type: Boolean,
      default: false
    },
    showTime: {
      type: Boolean,
      default: true
    },
    showRating: {
      type: Boolean,
      default: true
    },
    showWaypoints: {
      type: Boolean,
      default: true
    },
    allowDrag: {
      type: Boolean,
      default: false
    },
    allowReorder: {
      type: Boolean,
      default: false
    },
    allowDrop: {
      type: Boolean,
      default: false
    },
    selection: {
      type: Array,
      default: []
    }
  },

  watch: {
    'missions': {
      deep: true,
      immediate: true,
      handler(to) {
        this.missionsList = to;
      }
    },
    'selection': {
      immediate: true,
      handler(to: Array<MissionInfo>) {
        console.log("watch: selection", to)
        let sel: any = {};
        to.forEach(m => sel[m.guid] = true);
        this.selected = sel;
      }
    }
  },

  data() {
    return {
      missionsList: [] as IMissionInfoDetailed[],
      expandedList: {} as {[id: string]: boolean},
      selected: {} as {[id: string]: boolean},
      icons
    }
  },

  methods: {

    formatDistance,
    formatTime,
    formatRating,
    formatEfficiency,

    scaleDownGoogleImage(im: string) {
      return stripGoogleImageParams(im, "=w40");
    },

    selectionChanged() {
      console.log("selection changed")
      let sel = Object.keys(this.selected).map(guid => this.selected[guid] ? this.missionsList.find(m => m.guid == guid) : null).filter(m => m);
      console.log("new sel", sel)
      this.$emit('update:selection', sel);      
    },
    
    toggleExpandAll() {
      let expand = true;
      let keys = Object.keys(this.expandedList);
      if (keys.length > 0) expand = !this.expandedList[keys[0]];
      this.expandedList = {};
      this.missionsList?.forEach(m => this.expandedList[m.guid] = expand);
    },

    formatMissionOrder(order: MissionOrder): string {
      return MissionOrder[order];
    },

    formatWaypointType(type: WaypointType): string {
      return WaypointType[type];
    },

    formatWaypointObjective(objective: WaypointObjective): string {
      return WaypointObjectiveTexts[objective];
    },

    moveUp(row: IMissionInfoDetailed) {
      let i = this.missionsList.indexOf(row);
      if (i > 0) {
        let move = this.missionsList.splice(i, 1);
        this.missionsList.splice(i - 1, 0, ...move);
      }
      this.$emit('update:missions', this.missionsList)
    },

    moveDown(row: IMissionInfoDetailed) {
      let i = this.missionsList.indexOf(row);
      if (i < this.missionsList.length - 1) {
        let move = this.missionsList.splice(i, 1);
        this.missionsList.splice(i + 1, 0, ...move);        
      }
      this.$emit('update:missions', this.missionsList)
    }

  }
});
</script>

<style lang="scss" scoped>

.dragHandle {
  cursor: move;
}

table.missions {
  padding: 1em;
  width: 100%;
  th {
    text-align: left;
  }
  tr {
    vertical-align: top;
    td, th {
      &.r {
        text-align: right;
      }
    }
    td {
      padding-top: 1em;
    }
    td.title {
      * > {
        display: inline-block;
      }
    }
  }
}


.missionImage {
}

.titleRow {
  display: flex;
  flex-direction: row;
  .image {
    flex: 0;
    img {
      max-width: 32px;
      border-radius: 32px;
      margin-right: 1em;
    }
  }
  .title {
    vertical-align: center;
    flex: 1;
  }
}

.author {
  font-weight: bold;
}

.team-N {
}

.team-E {
  color: #017F01; // green;
}

.team-R {
  color: #005683; // #005684; //blue;
}

.waypoints {
  width: 100%;
  .number {
    width: 3em;
  }
  .unavailable {
    td {
      text-decoration: line-through;
      opacity: 0.7;
    }
  }
  .hidden {
    td {
      color: red;
    }
  }
  .title {
    width: 50%;
    .hidden {
      text-decoration: line-through;
    }
  }
}

.noSelection {
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}

.factbox {

  display: flex;
  flex-direction: row;
  padding-bottom: 1em;
  font-size: 0.9em;

  > * {
    flex: 1;
    text-align: center;
  }

  > *:first-child {
    text-align: left;
  }

  > *:last-child {
    text-align: right;
  }
}

</style>
