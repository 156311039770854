<template>

  <BannerMap
    v-if="banner"
    :missions="banner.missions"
    :banner="banner"
    style="width: 100%; height: 60vh"
    :class="{ fullscreen: fullscreen }"
    />
  
</template>

<script lang="ts">

import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { IBanner } from '../../../shared/src/types';

export default defineComponent({
  props: {
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }
  },
  data() {
    return {
      icons: Object.freeze(icons)
    }
  },
  computed: {
    fullscreen(): boolean {
      return this.$route.query.fullscreen == "true";
    }
  },
  methods: {
  }
});

</script>

<style lang="scss" scoped>

.fullscreen {
  height: 100% !important;
}

</style>